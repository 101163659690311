import React from 'react';

import '../../style/about.css';

export default () => (
  <div className="about-container">
    <div className="about-content">
      <p>Looking for a unique and fun experience for your bar, restaurant, corporate event, fundraiser or private function?</p>
      <p>Then be sure to call The QuizMaster for a fun-filled game of trivia questions covering a wide range of topics from movies to literature to pop culture to sports...</p>
      <div className="about-content-border" />
    </div>
  </div>
);
import React from 'react';

import Layout from '../components/Layout';
import ImageBanner from '../components/sections/ImageBanner';
import LogoBanner from '../components/sections/LogoBanner';
import About from '../components/sections/About';
import Contact from '../components/sections/Contact';
import Testimonials from '../components/sections/Testimonials';

export default ({ data, location }) => {
  return (
    <Layout>
      <div>
        <ImageBanner />
        <LogoBanner />
        <About />
        <Contact />
        <Testimonials />
      </div>
    </Layout>
  )
};

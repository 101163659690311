import React from 'react';
import { Grid } from 'semantic-ui-react';

import '../../style/image-banner.css';

// Gallery Images
import GalleryImage1 from '../../data/quizmaster-photos/quizmaster-photo-1.jpg';
import GalleryImage2 from '../../data/quizmaster-photos/quizmaster-photo-2.jpg';
import GalleryImage3 from '../../data/quizmaster-photos/quizmaster-photo-3.jpg';
import GalleryImage4 from '../../data/quizmaster-photos/quizmaster-photo-4.jpg';
import GalleryImage5 from '../../data/quizmaster-photos/quizmaster-photo-5.jpg';
import GalleryImage6 from '../../data/quizmaster-photos/quizmaster-photo-6.jpg';
import GalleryImage7 from '../../data/quizmaster-photos/quizmaster-photo-7.jpg';
import GalleryImage8 from '../../data/quizmaster-photos/quizmaster-photo-8.jpg';
import GalleryImage9 from '../../data/quizmaster-photos/quizmaster-photo-9.jpg';
import GalleryImage10 from '../../data/quizmaster-photos/quizmaster-photo-10.jpg';

const GalleryImages = [
  GalleryImage1,
  GalleryImage2,
  GalleryImage3,
  GalleryImage4,
  GalleryImage5,
  GalleryImage6,
  GalleryImage7,
  GalleryImage8,
  GalleryImage9,
  GalleryImage10
];

export default () => (
  <div className="image-banner-container">
    <div className="slideshow">
      <div className="slideshow-content">
        {[...GalleryImages, ...GalleryImages].map((image, index) => <div key={`${image}-${index}`} className="slideshow-image" style={{ backgroundImage: `url('${image}')`}}/>)}
      </div>
    </div>
  </div>
);
import React from 'react';
import AWS from 'aws-sdk';
import { Segment, Form, Header, Button, Container } from 'semantic-ui-react';

import emailService from '../../utils/email';
import '../../style/contact.css';

class Contact extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBot: '',
      name: '',
      email: '',
      content: '',
      sent: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    AWS.config.update({
      region: "us-east-1",
      credentials: new AWS.Credentials(
        process.env.AWS_ACCESS_KEY_ID,
        process.env.AWS_SECRET_ACCESS_KEY
      )
    });
  }

  handleChange(e, { name, value }) {
    this.setState({ [name]: value })
  }

  onSubmit() {
    emailService(this.state).then(res => this.setState({ sent: true }));
  }

  render() {
    return (
      <div className="contact-container" onSubmit={this.onSubmit}>
        <div className="contact-form">
          <Header as="h1">Interested?</Header>
          <p>Free free to give us a call or send us a text at 310.403.2699 or use the below contact form.</p>
          <div className="contact-social-media">
            <Button as="a" href="https://www.facebook.com/QuizMaster27/" icon="facebook" circular size="big" />
            <Button as="a" href="https://www.linkedin.com/company/the-quizmaster/" icon="linkedin" circular size="big" />
          </div>
          <Form>
            <Form.Input
              style={{ display: "none" }}
              onChange={this.handleChange}
              fluid
              name="isBot"
              value={this.state.isBot}
              placeholder="isBot"
            />
            <Form.Group widths='equal' onSubmit={this.onSubmit}>
              <Form.Input
                onChange={this.handleChange}
                fluid
                name="name"
                value={this.state.name}
                placeholder="Name"
                required
              />
              <Form.Input
                onChange={this.handleChange}
                fluid
                name="email"
                value={this.state.email}
                placeholder="Email"
                type="email"
                required
              />
            </Form.Group>
            <Form.TextArea
              onChange={this.handleChange}
              className="contact-form-textarea"
              name="content"
              value={this.state.content}
              placeholder=""
            />
            <Button type="submit" size="large">{this.state.sent ? "Sent" : "Submit Form"}</Button>
          </Form>
        </div>
      </div>
    );
  }
}

// fix content field issue
export default Contact;
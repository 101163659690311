import React from 'react';
import { Image } from 'semantic-ui-react';

import '../../style/logo-banner.css';

import MathBackground from '../../data/graphics/math-background.jpg';
import Logo from '../../data/quizmaster-logo.png';

export default () => (
  <div className="logo-banner-container" style={{ background: `url(${MathBackground})` }}>
    <div className="logo-banner-overlay">
      <Image src={Logo} centered size="large" />
    </div>
  </div>
);
import React from 'react';
import { Container, Header, Grid, Image } from 'semantic-ui-react';

import '../../style/testimonials.css';

import Client1 from '../../data/quizmaster-clients/boston-magazine-logo.jpg';
import Client2 from '../../data/quizmaster-clients/Fairway logo.jpg';
import Client3 from '../../data/quizmaster-clients/globoforce.jpg';
import Client4 from '../../data/quizmaster-clients/JBBBS - logo.jpg';
import Client5 from '../../data/quizmaster-clients/Karyopharm-blog-iamge.jpg';
import Client6 from '../../data/quizmaster-clients/MCLogo.b22cf2a2.png';
import Client7 from '../../data/quizmaster-clients/Natixis_logo.png';
import Client8 from '../../data/quizmaster-clients/Pine Brook Country Club.png';
import Client9 from '../../data/quizmaster-clients/Sweet Tomatoes.jpg';
import Client10 from '../../data/quizmaster-clients/The Home logo.png';
import Client11 from '../../data/quizmaster-clients/UnionStreet_RBF_01.png';
import Client12 from '../../data/quizmaster-clients/Women of  TBA logo.jpg';

const Clients = [
  Client1,
  Client2,
  Client3,
  Client4,
  Client5,
  Client6,
  Client7,
  Client8,
  Client10,
];

export default ({ data, location }) => {
  return (
    <div className="testimonials-container">
      <Container textAlign="center">
        <Header as="h1">Wicked Smaht Clients</Header>
      </Container>
      <Grid columns={3} className="client-logo-container" stackable>
        {Clients.map((logo, index) => (
            <Grid.Column key={logo} textAlign="center" verticalAlign="middle">
              <Image src={logo} size={index === 7 ? "small" : "medium"} className="client-logo"/>
            </Grid.Column>
        ))}
        <Grid.Column width={8} verticalAlign="middle" textAlign="right">
          <Image src={Client11} size="small" className="client-logo"/>
        </Grid.Column>
        <Grid.Column width={8} verticalAlign="middle" textAlign="left">
          <Image src={Client12} size="small" className="client-logo"/>
        </Grid.Column>
      </Grid>
    </div>
  )
}